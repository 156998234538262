import * as React from 'react'
import * as Yup from 'yup'
import loadable from '@loadable/component'

const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const WizardStep = loadable(() =>
    import('/src/components/Form/FormWizard/WizardStep')
)

export const validationSchemaStepRaf = Yup.object({
    raf: Yup.string()
})

const StepRaf = ({ userName }) => {
    return (
        <WizardStep
            title={`Thanks${userName && ` ${userName}`}, let's get you sorted`}
            requiredText={true}
        >
            <FieldSet
                align={'centered'}
                legend={'The person who referred you to us is?'}
                groupName={'raf'}
                required
            >
                <FieldSpacer>
                    <FeatureRadio
                        label={'My partner'}
                        groupName={'raf'}
                        name={'rafPartner'}
                        value={'partner'}
                        icon={'partner'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'My friend'}
                        groupName={'raf'}
                        name={'rafFriend'}
                        value={'friend'}
                        icon={'friend'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'My family'}
                        groupName={'raf'}
                        name={'rafFamily'}
                        value={'family'}
                        icon={'family'}
                    />
                </FieldSpacer>
            </FieldSet>
        </WizardStep>
    )
}

export default StepRaf
