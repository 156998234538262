import React, { useState, useContext } from 'react'
import loadable from '@loadable/component'
import { UserContext } from '/src/contexts/UserContext'
import { UtmContext } from '/src/contexts/UtmContext'
import { formatOfficeLineDob, formatHumanDateFromSingleValues } from '/src/functions/timeFormatter'
import submitLead from '/src/functions/forms/submitLead'

import StepAddress, { validationSchemaStepAddress } from '/src/components/Form/FormWizard/Steps/StepAddress'
import StepContactDetails, {
    validationSchemaStepContactDetails
} from '/src/components/Form/FormWizard/Steps/StepContactDetails'
import StepDob, { validationSchemaStepDob } from '/src/components/Form/FormWizard/Steps/StepDob'
import StepRaf, { validationSchemaStepRaf } from '/src/components/Form/FormWizard/Steps/StepRaf'
import StepSmoker, { validationSchemaStepSmoker } from '/src/components/Form/FormWizard/Steps/StepSmoker'
import StepUserDetails, { validationSchemaStepUserDetails } from '/src/components/Form/FormWizard/Steps/StepUserDetails'

const Wizard = loadable(() => import('/src/components/Form/FormWizard/Wizard'))

const RafJourney = () => {
    const [user] = useContext(UserContext)
    const [officeLineUtms] = useContext(UtmContext)

    const [isSubmitted, setSubmitted] = useState(false)
    const [userName, setUserName] = useState('')

    const successMessage = `<p><strong>Thank you for adding your details</strong></p><p>You will be redirected shortly...</p>`

    return (
        <Wizard
            initialValues={{
                title: '',
                firstname: '',
                lastname: '',
                sex: '',
                raf: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                smoker: '',
                address: '',
                postcode: '',
                phone: '',
                email: ''
            }}
            submitText="Get my quotes"
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    plid: user.plid,
                    title: values.title,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    sex: values.sex,
                    raf: values.raf,
                    dob: formatHumanDateFromSingleValues(values.dobDay, values.dobMonth, values.dobYear),
                    smoker: values.smoker,
                    address: values.address,
                    postcode: values.postcode,
                    phone: values.phone,
                    email: values.email
                }

                let olData = new URLSearchParams({
                    source: process.env.GATSBY_OFFICELINE_REFER_SOURCE,
                    app1_smoker: values.smoker,
                    app1_dob: formatOfficeLineDob(values.dobDay, values.dobMonth, values.dobYear),
                    app1_first_name: values.firstname,
                    app1_last_name: values.lastname,
                    app1_gender: values.sex,
                    app1_email: values.email,
                    app1_telephone_1: values.phone,
                    app1_postcode: values.postcode,
                    // Goes to 'sub-source' field in OL
                    affiliate_id: officeLineUtms.utmCampaign,
                    // Goes to 'sub-sub-source' field in OL (map the public lead ID to this field on this journey only)
                    sub_sub_source: user.plid
                })

                if (values.title !== 'Please select' || values.title !== 'Other') {
                    olData.append('app1_title', values.title)
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'leads-raf',
                    firebaseData: firebaseData,
                    olData: olData,
                    successPage: '/submission-success'
                }

                setSubmitted(true)

                await submitLead(submitOptions)
            }}
            isSubmitted={isSubmitted}
            successMessage={successMessage}
        >
            <StepUserDetails
                onSubmit={(values) => {
                    setUserName(values.firstname)
                }}
                validationSchema={validationSchemaStepUserDetails}
            />

            <StepRaf userName={userName} validationSchema={validationSchemaStepRaf} />

            <StepDob userName={userName} validationSchema={validationSchemaStepDob} />

            <StepSmoker userName={userName} validationSchema={validationSchemaStepSmoker} />

            <StepAddress userName={userName} validationSchema={validationSchemaStepAddress} />

            <StepContactDetails validationSchema={validationSchemaStepContactDetails} />
        </Wizard>
    )
}

export default RafJourney
